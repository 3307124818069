import React from 'react'
import { css } from 'linaria'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import BlogCategoriesBar from '../elements/BlogCategoriesBar'
import { Subheading, Lead, HeroHeading } from '../components/TextElements'
import rhythm from '../utils/rhythm'
import PostTile from '../components/Blog/PostTile'
import SectionContent from '../components/Section/Content'
import Section from '../components/Section'
import breakpoints from '../utils/tokens/breakpoints'

const categoryHeader = css`
  text-align: center;
  padding-top: ${rhythm(2)};
  max-width: ${rhythm(24)};
  margin: 0 auto;
`

const BlogCategory = ({ location, data: { category, posts } }) => (
  <Layout
    location={location}
    title={`${category.title} - Blog`}
    description={category.description}
    image={
      'https://bejamas-care.now.sh/' +
      encodeURIComponent(category.title) +
      '.png?theme=light&md=1&fontSize=100px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg'
    }
    postHeaderComponent={
      <BlogCategoriesBar location={location} currentCategory={category} />
    }
    showCtaInHeader={true}
  >
    <header className={categoryHeader}>
      <Subheading>Category</Subheading>
      <HeroHeading>{category.title}</HeroHeading>
      <Lead>{category.description}</Lead>
    </header>

    <Section>
      <SectionContent
        className={css`
          ${{
            gridTemplateColumns: '100%',
            columnGap: rhythm(2),
            rowGap: rhythm(2),
            maxWidth: '100%',
            alignItems: 'self-start',
            [`@media (min-width: ${breakpoints.md})`]: {
              gridTemplateColumns: '1fr 1fr',
              margin: `0 ${rhythm(3)}`
            }
          }};
        `}
      >
        {posts &&
          posts.edges.map(({ node }) => (
            <PostTile
              post={node}
              articleClassName={css`
                ${{
                  display: 'grid',
                  gridTemplateColumns: '100%',
                  gridTemplateAreas: '"cover" "content"',
                  rowGap: rhythm(3 / 2),
                  columnGap: rhythm(3 / 2),
                  [`@media (min-width: ${breakpoints.lg})`]: {
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateAreas: '"content cover"'
                  }
                }};
              `}
              coverClassName={css`
                ${{ marginBottom: 0 }};
              `}
            />
          ))}
      </SectionContent>
    </Section>
  </Layout>
)

export default BlogCategory

export const pageQuery = graphql`
  query blogCategoryQuery($slug: String!) {
    category: blogCategoriesYaml(slug: { regex: $slug }) {
      title
      description
      slug
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { regex: $slug } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`
